import { getKcContext } from "keycloakify/lib/getKcContext";

export const { kcContext } = getKcContext<
  // NOTE: A 'keycloakify' field must be added
  // in the package.json to generate theses pages
  // https://docs.keycloakify.dev/build-options#keycloakify.extrapages
  { pageId: "login.ftl" } | { pageId: "info.ftl" } | { pageId: "error.ftl" }
>({
  // Uncomment to test the login page for development.
  // mockPageId: "login.ftl",
  mockData: [
    {
      pageId: "login.ftl",
      message: {
        type: "error",
        summary: "user not found",
      },
      // login: {
      //   username: "test@bostongene.com",
      // },
      realm: {
        loginWithEmailAllowed: true,
        registrationEmailAsUsername: false,
        rememberMe: true,
        resetPasswordAllowed: true,
      },
      social: {
        providers: [
          {
            alias: "google",
            displayName: "Google",
            loginUrl: "https://accounts.google.com/login",
            providerId: "google",
          },
          {
            alias: "salesforce",
            displayName: "SalesForce Portal",
            loginUrl: "https://bostongene.mysite.com/login",
            providerId: "oidc",
          },
        ],
      },
    },
    {
      pageId: "info.ftl",
      message: {
        summary: "You are already signed in.",
      },
      client: {
        baseUrl: "https://bostongene.mysite.com",
      },
    },
    {
      pageId: "error.ftl",
      message: {
        summary: "Got unexpected error from Google IdP.",
      },
    },
  ],
});

export type KcContext = NonNullable<typeof kcContext>;
