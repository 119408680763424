import { createRoot } from "react-dom/client";
import { StrictMode, lazy, Suspense } from "react";
import { kcContext } from "./KcApp/kcContext";

const KcApp = lazy(() => import("./KcApp"));

if (kcContext === undefined) {
  throw new Error(
    "This app is a Keycloak theme. It isn't meant to be deployed outside of Keycloak"
  );
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Suspense>
      <KcApp kcContext={kcContext} />
    </Suspense>
  </StrictMode>
);
